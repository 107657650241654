.ticket-attachment-preview{
    max-width: 100px;
    max-height: auto;
}

.status_nou{
    color: #0d6efd;
}

.status_deschis{
    color: #0dcaf0;
}

.status_in_lucru{
    color: #ffc107;
}
.status_rezolvat{
    color: #198754;
}
.status_blocat{
    color: #dc3545;
}

td {
    vertical-align: middle;
    }

  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

@media (min-width: 576px) {
    .h-sm-100 {
        height: 100%;
    }
}

.img-logo-stb{
    max-width: 150px;
    height:auto;
}

strong {
    font-weight: bold;
}

@media (min-width: 1200px) {
    .sidebar_container{
        height: 100vh;
    }
}

